import React from 'react';
import { useDate } from '../Context/DateContext';
import './css/ButtonBuscar.css'; 

const ButtonBuscar = () => {
  const { startDate, endDate } = useDate();

  const redirectToPage = () => { 
    // Construye la URL con las fechas seleccionadas
    const url = `https://direct-book.com/properties/refugiomisteriosdelelqui?locale=es&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=CLP&checkInDate=${startDate}&checkOutDate=${endDate}&trackPage=no`;
    
    // Redirige a la URL
    window.location.href = url;
  };

  return (
    <button className="ButtonBuscar" onClick={redirectToPage}>RESERVAR</button>
  );
};

export default ButtonBuscar;