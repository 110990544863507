import React from 'react';
import logoWhite from '../../images/Logos/LogoSidebar.webp';
import logoBlack from '../../images/Logos/LogoHeader.png';
import SidebarHeader from '../Sidebar/SidebarHeader'; // Importa el componente SidebarHeader 
import ButtonReservarHeader from '../Button/ButtonReservarHeader'

import './css/header.css'; // Importa el archivo de estilos CSS

const Header = ({ scrollPosition }) => {
  const headerClass = scrollPosition === 1 ? 'header-solid' : 'header-transparent';
  const logo = scrollPosition === 1 ? logoBlack : logoWhite; // Selecciona el logo en función de scrollPosition
  const columnClass = scrollPosition === 1 ? 'column' : 'column hidden'; // agregar column hiden si se quiere ocultar en el primer scroll

  return (
    <header className={headerClass}>
      <div className="logo-container-header">
        <a href="/">
          <img src={logo} alt="Logo de la empresa" className="logo" />
        </a>
      </div>
      <div className={columnClass}>
        <ButtonReservarHeader />
      </div>
      <div className="button-container">
        <div className="sidebar-container-header">
          <SidebarHeader />
        </div>
      </div>

    </header>
  );
};

export default Header;
