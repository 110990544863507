import React, { useState, useRef } from 'react';
import './css/ImageSlider.css';
import imagen6_Inicio from '../../images/Experiencia/Imagen6_Inicio.jpeg';
import imagenCabañaFamiliar from '../../images/Cabañas/CabañaFamiliarSlider.png';
import imagenCabañaDoble from '../../images/Cabañas/CabañaDobleSlider.png';
import imagenCabañaDobleDeluxe from '../../images/Cabañas/CabañaDobleDeluxe.png';
import imagenObservatorio from '../../images/Experiencia/ImagenObservatorio.jpeg';
import imagenSpa from '../../images/Experiencia/ImagenSpa.png';
import imagenTours from '../../images/Experiencia/ImagenTours.png'; 
import imagenReserva from '../../images/Reserva/ImagenReserva.jpg';
import Calendar from '../Calendar/Calendar';


const ImageSlider = ({ tipo }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoaded = () => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1; 
      videoRef.current.loop = true; // Asegurar que el video esté en bucle
      videoRef.current.play(); // Reproducir el video una vez cargado
    }
    setVideoLoaded(true);
  };

  let imagen;
  let texto;
  let textoExperiencia;
  let SliderOverlay;
  switch (tipo) {
    case 'Inicio':
      imagen = imagen6_Inicio;
      texto = 'CONOCE NUESTRO REFUGIO';
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh text-xs font-bold lg:flex';
      break;
    case 'CabañaFamiliar':
      imagen = imagenCabañaFamiliar;
      texto = 'CONOCE NUESTRA CABAÑA FAMILIAR';
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh text-xs font-bold  lg:flex';
      break;
    case 'CabañaDoble':
      imagen = imagenCabañaDoble;
      texto = 'CONOCE NUESTRA CABAÑA DOBLE';
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh text-xs font-bold lg:flex';
      break;
    case 'Observatorio':
      imagen = imagenObservatorio;
      texto = 'TOUR ASTRONÓMICO DE LUJO EN';
      textoExperiencia = 'OBSERVATORIO GALILEO';
      SliderOverlay = 'TextoExperiencias'
      break;
    case 'Spa':
      imagen = imagenSpa;
      texto = 'EXCLUSIVA SALA DE';
      textoExperiencia = 'MASAJES';
      SliderOverlay = 'TextoExperiencias'
      break;
    case 'Tours':
      imagen = imagenTours;
      texto = 'SORPRÉNDETE EN';
      textoExperiencia = 'PISCO ELQUI';
      SliderOverlay = 'TextoExperiencias'
      break; 
    case 'Reserva':
      imagen = imagenReserva;
      texto = 'RESERVA TU CABAÑA'; 
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh hidden lg:flex';
      break;
    default:
      imagen = imagenCabañaDobleDeluxe;
      texto = 'CONOCE NUESTRA CABAÑA DOBLE DELUXE';
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh hidden lg:flex';
  }

  return (
    <div className="image-slider">
      <div className='overlay'></div>

      <div className='Fondo'> 
      </div>
      {tipo === 'Inicio' || tipo === 'Observatorio' ? (
        <div className='w-screen h-screen object-cover'>
          {!videoLoaded && <img src={imagen} className='w-screen h-screen object-cover' />}
          <video
            ref={videoRef}
            className='w-screen h-screen object-cover'
            src={tipo === 'Inicio' ? '/images/videos/hotel.mp4' : '/images/videos/observatorio.mp4'}
            autoPlay
            muted
            loop
            controls={false}
            playsInline
            onLoadedData={handleVideoLoaded}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <img src={imagen} className='w-screen h-screen object-cover ' />
      )}
      {(tipo === 'Inicio'  ||  tipo === 'Reserva') && (
        <Calendar/>
      )}
      <div className={SliderOverlay}>
        <p>{texto}</p>
        {(tipo === 'Observatorio' || tipo === 'Spa' || tipo === 'Tours') && (
          <p className='Destacado'>{textoExperiencia}</p>
        )}
        {(tipo !== 'Observatorio' && tipo !== 'Spa' && tipo !== 'Tours') && (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="arrow-down" onClick={() => window.scrollTo({ top: 710, behavior: 'smooth' })}>
            <path fillRule="evenodd" d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg>
        )}
      </div>
    </div>
  );
};

export default ImageSlider;