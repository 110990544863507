import React, { useState } from 'react';
import { useDate } from '../Context/DateContext';
import { useShoppingCart } from '../Context/ShoppingCartContext';
import { useNavigate } from 'react-router-dom';

const FormPay = () => {
    const { cart } = useShoppingCart();
    const { startDate, endDate } = useDate();
    const navigate = useNavigate();
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [nationality, setNationality] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');

    // Asegurarse de que startDate y endDate estén definidos
    if (!startDate || !endDate) {
        console.error("startDate o endDate no están definidos");
        return;
    }

    // Calcular el total
    const subtotal = cart.reduce((total, item) => total + item.precio, 0);
    const iva = subtotal * 0.19; // Asumiendo que IVA es del 19%
    const total = Math.round(subtotal + iva); // Asegurarse de que sea un número entero

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Registrar al cliente en la base de datos
            const customerData = {
                firstname,
                lastname,
                email,
                nationality,
                documentType,
                documentNumber
            };

            const customerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerData)
            });

            if (!customerResponse.ok) {
                throw new Error('Error al registrar el cliente');
            }

            const customerResult = await customerResponse.json();
            console.log('Cliente registrado:', customerResult);

            // Primero, generamos la reserva
            const reservationPromises = cart.map(async (item) => {
                const reservationData = {
                    fechaInicio: startDate,
                    fechaFin: endDate,
                    property_id: item.id, // Suponiendo que el id de la cabaña está en item.id
                    customer_id: customerResult.id // Usar el ID del cliente recién creado
                };

                const reservationResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(reservationData)
                });

                if (!reservationResponse.ok) {
                    throw new Error(`Error al crear la reserva para la propiedad ${item.id}`);
                }

                return reservationResponse.json();
            });

            const reservations = await Promise.all(reservationPromises);
            console.log('Reservas creadas:', reservations);

            // Ahora, crear la preferencia de pago en Mercado Pago
            const paymentData = {
                title: `Reserva de cabañas - ${firstname} ${lastname}`,
                quantity: 1, // Suponiendo que es una única transacción
                unit_price: total // Asegúrate de que sea un número entero
            };

            const paymentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(paymentData)
            });

            if (!paymentResponse.ok) {
                throw new Error('Error al crear la preferencia de pago en Mercado Pago');
            }

            const paymentResult = await paymentResponse.json();
            console.log('Preferencia de pago creada:', paymentResult);

            if (paymentResult && paymentResult.success && paymentResult.data) {
                window.location.href = paymentResult.data.init_point;
            } else {
                throw new Error('No se pudo obtener el enlace de pago. Respuesta recibida: ' + JSON.stringify(paymentResult));
            }
        } catch (error) {
            console.error('Error en el proceso de reserva o pago:', error);
            alert('Hubo un problema al procesar tu solicitud.');
        }
    };

    return (
        <div className="flex justify-center items-start" style={{ backgroundColor: '#433B24', width: '50%', height: 'auto', borderRadius: '15px' }}>
            <div className="relative flex flex-col justify-center">
                <div className="w-full p-6 m-auto bg-transparent">
                    <h1 className="text-3xl text-center text-white">
                        INGRESA TUS DATOS
                    </h1>
                    <form className="mt-6" onSubmit={handleSubmit}>
                        <div className="mb-2 flex flex-row space-x-4">
                            <div className="flex flex-col">
                                <label
                                    htmlFor="firstname"
                                    className="block text-md text-white text-left"
                                >
                                    NOMBRE
                                </label>
                                <input
                                    type="text"
                                    id="firstname"
                                    className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-white focus:ring-white focus:outline-none focus:ring focus:ring-opacity-40"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor="lastname"
                                    className="block text-md text-white text-left"
                                >
                                    APELLIDOS
                                </label>
                                <input
                                    type="text"
                                    id="lastname"
                                    className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-white focus:ring-white focus:outline-none focus:ring focus:ring-opacity-40"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                            <label
                                htmlFor="email"
                                className="block text-md text-white text-left"
                            >
                                EMAIL
                            </label>
                            <input
                                type="email"
                                id="email"
                                className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-white focus:ring-white focus:outline-none focus:ring focus:ring-opacity-40"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label
                                htmlFor="nationality"
                                className="block text-md text-white text-left"
                            >
                                NACIONALIDAD
                            </label>
                            <input
                                type="text"
                                id="nationality"
                                className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-white focus:ring-white focus:outline-none focus:ring focus:ring-opacity-40"
                                value={nationality}
                                onChange={(e) => setNationality(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-2">
                        <label
                            htmlFor="documentType"
                            className="block text-md text-white text-left"
                        >
                            TIPO DE DOCUMENTACIÓN
                        </label>
                        <select
                            id="documentType"
                            className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-white focus:ring-white focus:outline-none focus:ring focus:ring-opacity-40"
                            value={documentType}
                            onChange={(e) => setDocumentType(e.target.value)}
                            required
                        >
                            <option value="" disabled>Seleccione una opción</option>
                            <option value="Cédula Identidad">CÉDULA IDENTIDAD</option>
                            <option value="Pasaporte">PASAPORTE</option>
                        </select>
                    </div>
                        <div className="mb-2">
                            <label
                                htmlFor="documentNumber"
                                className="block text-md text-white text-left"
                            >
                                NÚMERO DE RUT O PASAPORTE
                            </label>
                            <input
                                type="text"
                                id="documentNumber"
                                className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-white focus:ring-white focus:outline-none focus:ring focus:ring-opacity-40"
                                value={documentNumber}
                                onChange={(e) => setDocumentNumber(e.target.value)}
                                required
                            />
                        </div>
                                            {/* Mostrar el monto total a pagar */}
                    <div className="mt-6 text-center text-white">
                        <p>TOTAL A PAGAR: <span className='text-xl font-semibold'>${total}</span></p>
                    </div>
                        <button type="submit" className="mt-4 px-4 py-2 bg-gray-100 text-elqui font-bold rounded-md">
                            CONTINUAR AL PAGO
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default FormPay;