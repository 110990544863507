import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import imagen from '../images/Reserva/ImagenFormulario.png';
import './css/FormularioPago.css';
import Header from '../components/Header/header';
import FormPay from '../components/Form/FormPay';
import { useShoppingCart } from '../components/Context/ShoppingCartContext';
import Footer from '../components/Footer/Footer';






const FormularioPago = () => {
    const { cart } = useShoppingCart();
    const navigate = useNavigate();

    useEffect(() => {
        if (cart.length === 0) {
            navigate('/Reserva');
        }
    }, [cart, navigate]);
    return (
        <div className="contenedorFormularioPago" >
            <Header scrollPosition={0} className="fixed-header-FormularioPago" />
            <div className="cuerpoFormularioPago">
                <div className='ContenedorImagenFormulario'>
                    <img src={imagen} alt="Imagen" />
                    <div className="formPayWrapper">
                        <FormPay />
                    </div>
                </div>
            </div>
            <div className='outer-div-Footer'>
                <Footer />
            </div>
        </div>
    );
};

export default FormularioPago;