import React, { useState, useEffect } from 'react';
import { useShoppingCart } from '../Context/ShoppingCartContext';

const CardCabañaDisponible = ({ cabaña }) => {
  const [cabañasState, setCabañasState] = useState(cabaña);
  const { addItemToCart } = useShoppingCart();

  const handleGuestChange = (id, value) => {
    if (cabañasState.id === id) {
      setCabañasState({ ...cabañasState, personas: value });
    }
  };

  const handleAddItem = () => {
    addItemToCart(cabañasState);
  };

  useEffect(() => {
    if (cabaña.personas === undefined || cabaña.personas === null) {
      handleGuestChange(cabaña.id, 1);
    }
    // Asegúrate de que el efecto se ejecute solo una vez para inicializar
  }, [cabaña]);

  return (
    <div className={`contenedor grid grid-row-2 bg-gray-100 shadow-lg font-montserrat gap-2 relative`}>
      <div className='carrusel-info grid grid-cols-1 gap-4'>
        <div className='carrusel '>
          <img className='w-full h-64 object-cover' src={cabaña.foto} alt={cabaña.tipo} />
        </div>

        <div className='info text-center'>
          <div className='info-titulo text-xl font-bold pb-2 pt-4'>
            {cabaña.tipo}
          </div>
          <div className='info-descripcion pt-2 px-4'>
            {cabaña.descripcion}
          </div>
          <div className='info-link pb-2'>
            <a className='underline' href={`#`}> {/* No se proporcionó un link, así que usamos un placeholder */}
              VER DETALLES
            </a>
          </div>
        </div>
      </div>

      <div className='tarifa-boton flex flex-col py-4'>
        <div className='tarifa text-center gap-y-2 flex flex-row justify-center gap-x-10 mb-5'>
          <div className='font-bold flex flex-col text-center items-center'>CAPACIDAD
            <p className='p-2 mx-1'>{cabaña.capacidad} personas</p>
          </div>
          <div className='font-bold flex flex-col items-center'>VALOR NOCHE
            <p className='p-2 mx-1 text-center w-min'>${cabaña.precio}</p>
          </div>
        </div>
        <div className='boton '>
          <button className='bg-elqui w-3/4 py-2 text-white h-auto border-none cursor-pointer font-bold' onClick={handleAddItem}>
            AGREGAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardCabañaDisponible;