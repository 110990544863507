import React, { useState } from 'react';
import './css/CondicionesGenerales.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const CondicionesGenerales = () => {
    const [isCheckInOutVisible, setIsCheckInOutVisible] = useState(false);
    const [isCondicionesGeneralesVisible, setIsCondicionesGeneralesVisible] = useState(false);

    const toggleCheckInOutVisibility = () => {
        setIsCheckInOutVisible(!isCheckInOutVisible);
    };

    const toggleCondicionesGeneralesVisibility = () => {
        setIsCondicionesGeneralesVisible(!isCondicionesGeneralesVisible);
    };

    return (
        <div className='Agrupador'>
            <div className="CondicionesGenerales p-10">
                <button onClick={toggleCheckInOutVisibility} className="toggle-button">
                    <p className='Titulo w-full font-bold'>CHECK IN / CHECK OUT <FontAwesomeIcon icon={faChevronDown} /></p>
                </button>
                {isCheckInOutVisible && (
                    <>
                        <p className='Horas'><strong>Hora De Ingreso:</strong> Desde las 15:00 hasta las 21:30 hrs.  <strong>Hora De Salida: </strong>12:00 hrs.</p>
                        <p className='Horas'>Check-out tardío sujeto a disponibilidad, costo adicional de $ 10.000.- por hora a partir de 12:30 horas</p>
                        <p className='Horas'><strong>Horario de Recepción:</strong> 14:00 a 21:30 Horas.</p>
                    </>
                )}
            </div>

            <div className="CondicionesGenerales p-10">
                <button onClick={toggleCondicionesGeneralesVisibility} className="toggle-button">
                    <p className='Titulo w-full font-bold'>TÉRMINOS Y CONDICIONES <FontAwesomeIcon icon={faChevronDown} /></p>
                </button>
                {isCondicionesGeneralesVisible && (
                    <>
                        <p className='Descripcion font-bold'>Condiciones generales</p> 
                        <p className='Detalles'>
                        Se permite fumar solo en lugares al aire libre, no en habitaciones ni en lugares comunes cerrados, esto según lo dispuesto en la Ley de Tabaco N° 20.660
                        En nuestro hotel, para ser amigables con el medio ambiente, no cambiamos las toallas todos los días. Se las dejaremos colgadas en el baño. Si usted necesita cambio de toalla, debe pedirlo en la administración.
                        </p>
                        <p className='Descripcion font-bold'>Otras condiciones</p> 
                        <p className='Detalles'>
                        Refugio Misterios del Elqui incluye desayuno continental servido en su cabaña entre las 8:30 y las 9:30 horas.
                        El aseo de la cabaña se realiza entre 12:00 y 14:00 horas.
                        Las instalaciones del hotel solo podrán ser utilizadas por los pasajeros registrados en el hotel.
                        No se permiten mascotas.
                        El uso del quincho es solo desde las 18:00 a las 21:00 horas y deberá ser reservado sin costo en la administración.
                        El uso del hot tub tiene un costo adicional y se reserva en administración.
                        </p>
                        <p className='Descripcion font-bold'>Política de cancelación</p> 
                        <p className='Detalles'>
                        El cliente puede cancelar la reserva de forma gratuita hasta 7 días antes de la fecha de llegada (check in).
                        No se realizara devolución de la reserva si el cliente cancela su reserva durante los 7 días antes de la fecha de llegada. No se permite cambio de fecha de reserva durante los 7 días antes de la fecha de llegada (check in)
                        </p>
                        <p className='Descripcion font-bold'>Política de pago</p> 
                        <p className='Detalles'>
                        Para asegurar su reserva se requiere el pago de la primera noche de su estadía, este pago debe realizarse 7 días antes de su llegada (check in). El resto del pago se cobrará a su llegada.
En el caso de las reservas directas (correo electrónico) se enviara los datos para realizar el pago de su reserva 7 días antes de su llegada (check in)

En el caso de reservas de más de una cabaña, se cobrara la primera noche de la estadía  el mismo día de realizada dicha reserva.</p>
                    </>
                )}
            </div>
        </div>
    );
}

export default CondicionesGenerales;