import React, { useEffect, useState } from 'react';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import CardCabañas from '../components/Card/CardCabañas';
import CondicionesGenerales from '../components/Card/CondicionesGenerales';
import ButtonReserva from '../components/Button/ButtonReserva';
import { useParams, useNavigate } from 'react-router-dom';
import { useWindowScroll } from '@uidotdev/usehooks';
import './css/DescripcionCabañas.css';

const DescripcionCabañas = () => {
    const { tipo } = useParams();
    const [{ y }] = useWindowScroll();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [anchoVentana, setAnchoVentana] = useState(window.innerWidth);
    const navigate = useNavigate();
    useEffect(() => {
        if (tipo !== 'CabañaFamiliar' && tipo !== 'CabañaDoble' && tipo !== 'CabañaDobleDeluxe') {
            navigate('/');
        }
    }, [tipo, navigate]); 
    useEffect(() => {
        const handleResize = () => {
            setAnchoVentana(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => { 
        if (window.innerWidth <= 1135) { 
            setScrollPosition(0);
        } 
        else if (y > 700) {
            setScrollPosition(1);
        } 
        else {
            setScrollPosition(0);
        }
    }, [y, anchoVentana]);    


    return (
        <div className='contenedorDescripcionCabañas'>
            <Header scrollPosition={scrollPosition} className="fixed-header" />

            <div className='cuerpoDescripcionCabañas'> 
                <CardCabañas Tipo={tipo}/>
            </div>

            <div className='Codiciones'>
                < CondicionesGenerales />
            </div>
            
            <div className='outer-div-Footer-descriptionCabañas '>
                <Footer />
            </div>
        </div>
    );
};

export default DescripcionCabañas;