import React from 'react';
import './css/Cabañas.css';
import './css/inicio.css';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import TiposCabañas from '../components/Card/TiposCabañas';



const Cabañas = () => {
  return (
    <div className="CabañasPage" >
      <Header scrollPosition={1} className="fixed-header-Cabañas" />
      <div className="cuerpoCabañas">
        <div className='ComponenteCabañas mt-20'  data-aos="fade-left">
          <TiposCabañas />
        </div> 
        <div className='FooterCabañas'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Cabañas;
