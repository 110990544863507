import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CardCabañaDisponible from './CardCabañaDisponible';
import './css/ListaCabañasDisponibles.css';

const ListaCabañasDisponibles = () => {
  const [cabanas, setCabanas] = useState([]);
  const location = useLocation();

  // Extraer las fechas de la URL
  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get('fechaInicio');
  const endDate = searchParams.get('fechaFin');


  // Obtener la URL del backend desde la variable de entorno
  const apiUrl = process.env.REACT_APP_BACKEND_URL.replace(/\/$/, ''); // Elimina la barra final si existe


  useEffect(() => {
    const fetchCabanas = async () => {
      try {
        const response = await fetch(`${apiUrl}/availability?fechaInicio=${startDate}&fechaFin=${endDate}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        
        const data = await response.json();
        setCabanas(data.availableProperties || []); // Acceder correctamente a las propiedades disponibles
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (startDate && endDate) {
      fetchCabanas();
    }
  }, [startDate, endDate, apiUrl]);

  return (
    <div className='w-full grid lg:grid-cols-3 gap-4'>
      {cabanas.length > 0 ? (
        cabanas.map(cabaña => (
          <CardCabañaDisponible key={cabaña.id} cabaña={cabaña} />
        ))
      ) : (
        <p className='textSinDisponibilidad text-center w-full col-span-3 text-elqui'>No hay cabañas disponibles para las fechas seleccionadas.</p>
      )}
    </div>
  );
};

export default ListaCabañasDisponibles;