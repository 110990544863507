import React, { useEffect, useState } from 'react';
import './css/Servicios.css';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import Servicio1 from '../images/Servicios/Servicio1.png';
import Servicio2 from '../images/Servicios/Servicio2.png';
import Servicio3 from '../images/Servicios/Servicio3.png';
import Servicio4 from '../images/Servicios/Servicio4.png';
import Servicio5 from '../images/Servicios/Servicio5.png';
import Servicio6 from '../images/Servicios/Servicio6.png';
import { useWindowScroll } from '@uidotdev/usehooks';

const Servicios = () => {
  const [{ y }] = useWindowScroll();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (y > 700) {
      setScrollPosition(1);
    } else {
      setScrollPosition(1);
    }
  }, [y]);
  return (
    <div className="contenedorServicios" >
      <Header scrollPosition={scrollPosition} className="fixed-header-Servicios" />
      <div className='cuerpo-Servicios'>
        <div className='EspacioBlancoServicios'></div>
        <div className='ServiciosDisponibles' data-aos="fade-up" >
          <p className='Titulo'><strong>SERVICIOS</strong></p>
          <div className='FilasServicios'>
            <div className='BoxServicio'>
              <img src={Servicio1} alt="Servicio1" />
              <p className='Titulo'><strong>TINAJA CALIENTE</strong></p> 
              <p className='Descripcion'>Ubicado en un entorno tranquilo y rodeado de la exuberante naturaleza del Valle del Elqui, nuestra tinaja te invita  sumergirte en un estado de total bienestar. Disfruta de una vista panorámica de las estrellas durante la noche o del paisaje circundante durante el día, mientras te entregas al placer de sumergirte en aguas cálidas.</p>
            </div>
            <div className='BoxServicio'>
              <img src={Servicio2} alt="Servicio1" /> 
              <p className='Titulo'><strong>QUINCHO</strong></p> 
              <p className='Descripcion'>Situada en medio de la belleza natural del Valle del Elqui, nuestra zona de parrilla es el lugar perfecto para reunirte con amigos y familiares mientras disfrutas de deliciosas comidas al estilo chileno. Con amplias instalaciones y vistas panorámicas, podrás asar carnes frescas, pescados y verduras mientras compartes momentos inolvidables alrededor del fuego.</p>
            </div>
          </div>
          <div className='FilasServicios'>
            <div className='BoxServicio'>
              <img src={Servicio3} alt="Servicio1" />
              <p className='Titulo'><strong>PISCINA EXCLUSIVA</strong></p> 
              <p className='Descripcion'>Refresca tu cuerpo y revitaliza tu espíritu en nuestra impresionante piscina. Sumérgete en aguas cristalinas mientras disfrutas de las vistas panorámicas de los majestuosos paisajes que nos rodean. Ya sea que desees nadar unas vueltas o simplemente relajarte junto a la piscina, nuestro oasis acuático te brinda el escape perfecto para desconectar y renovarte.</p>
            </div>
            <div className='BoxServicio'>
              <img src={Servicio5} alt="Servicio1" />
              <p className='Titulo'><strong>ÁREA DE WIFI</strong></p> 
              <p className='Descripcion'>En nuestras instalaciones podras encontrar una acojedora área de wifi, para que puedas mantenerte conectado a internet sin problemas.</p>
            </div>
            </div>
            <div className='FilasServicios'>
            <div className='BoxServicio'>
              <img src={Servicio6} alt="Servicio1" />
              <p className='Titulo'><strong>MASAJES</strong></p> 
              <p className='Descripcion'>Sumérgete en un oasis de relajación en nuestra serena sala de masajes, donde la tranquilidad del Valle del Elqui se combina con técnicas terapéuticas expertas. Disfruta de un ambiente tranquilo y acogedor mientras nuestros terapeutas profesionales te guían hacia un estado de calma y bienestar. Con una cuidadosa atención a cada detalle, te invitamos a dejar atrás el estrés y renovar tu cuerpo y mente en nuestro santuario de rejuvenecimiento.</p>
            </div>
          </div>
        </div>

        <div className='outer-div-Footer-Servicios'>
          <Footer />
        </div>
      </div>
    </div>

  )
}
export default Servicios;