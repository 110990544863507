import React from 'react';
import './css/ButtonReservarHeader.css'; // Asegúrate de tener este archivo CSS en la misma carpeta

const ButtonReservarHeader = () => {
  const handleClick = () => {
    // Redirigir al enlace externo
    const url = 'https://direct-book.com/properties/refugiomisteriosdelelqui?locale=es&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=CLP&checkInDate=2024-09-20&checkOutDate=2024-09-25&trackPage=no';
    window.location.href = url;
  };

  return (
    <button className="ButtonReservarHeader" onClick={handleClick}>
      RESERVAR
    </button>
  );
};

export default ButtonReservarHeader;