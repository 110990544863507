import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './css/SidebarHeader.css'; // Importa el archivo de estilos CSS
import logo from '../../images/Logos/LogoSidebar.webp'; // Importa la imagen del logo

const SidebarHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const closeSidebar = (event) => {
    if (!event.target.closest('.sidebar')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', closeSidebar);
    return () => {
      document.body.removeEventListener('click', closeSidebar);
    };
  }, []);

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames="sidebar"
        unmountOnExit
      >
        <div className="sidebar-background">
          <div className="sidebar-content">
            <div className="sidebar-header">
              <div className="logo-container-Sidebar">
                <a href="/">
                  <img src={logo} alt="Logo de la empresa" className="logo" />

                </a>
              </div>
            </div>
            <div className="sidebar-body">
              <a href="/" className="menu-item">Inicio</a>
              <a href="/Cabañas" className="sidebar-item">Cabañas</a>
              <a href="/" className="sidebar-item hidden">Como llegar</a>
              <div className="experiencias-item" onClick={toggleSidebar}>
                <div className="experiencias-content">
                  Experiencias
                </div>
                  <a href="/Experiencias/Observatorio" className="submenu-item">Observatorio</a>
                  <a href="/Experiencias/Tours" className="submenu-item">Tours externos</a>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>

    </>
  );
};

export default SidebarHeader;
