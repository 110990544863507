import React from 'react';
import { Link } from 'react-router-dom';  // Importación añadida para que funcione el Link
import './css/Footer.css';
import logo from '../../images/Logos/LogoSidebar.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ButtonHablemos from '../Button/ButtonHablemos';
const Footer = () => {
  return (
    <div className="footer">
      <div className="logo-container-footer">
        <img src={logo} alt="Imagen Logo" className='logo-footer' />
        {/*<Link className='buttonFooter' to={`/anfitrion`}>PANEL ANFITRION</Link>*/}
      </div> 
      <div className='column-footer-description'> 
          <p className='p1'>Te esperamos en Refugio Misterios del Elqui</p> 
          <p className='p2'>Ubicado en el corazón del Valle de Elqui, nuestro refugio es un santuario de paz y serenidad, donde la naturaleza y la comodidad se encuentran para ofrecerle una experiencia inolvidable.</p>

      </div>
      <div className="column-footer-rows">
        <p className="contact-title">Contacto</p> 
        <p className="contact-info">Arturo Prat S/N, Pisco Elqui Chile</p>
        <p className="contact-info">+56 9 4400 40 32</p>
        <p className="contact-info"></p>
        <p className="contact-info">reservas@misteriosdelelqui.cl</p>
        <ButtonHablemos />
        
      </div>
      <div className="column-footer-rows">
        <p className="contact-title">Encuentranos en</p>       
        <div className="column-footer-columns gap-x-5">
          <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="rrss">
            <FontAwesomeIcon icon={faInstagram} size="" className="text-white" />
          </a>
          <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="rrss">
            <FontAwesomeIcon icon={faFacebook} size="" className="text-white" />
          </a>
        </div> 
        <div className='column-footer-description'> 
          <p className='p2'><span className='text-white'>Desarrollado por</span> <a className='font-bold' href="www.bucont.com">bucont.com</a></p>

      </div>      </div> 

    </div>
  );
};

export default Footer;