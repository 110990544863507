import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useDate } from '../Context/DateContext';
import { useShoppingCart } from '../Context/ShoppingCartContext';
import { useNavigate } from 'react-router-dom';

const DetallesReserva = () => {  
  const { startDate, endDate } = useDate(); 
  const { cart, clearCart, removeItemFromCart } = useShoppingCart();  
  const navigate = useNavigate();
  
  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    return new Date(date).toLocaleDateString('es-ES', options);
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

    // Calcular la cantidad de noches
    const calculateNights = (start, end) => {
      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();
      const diffTime = endTime - startTime;
      return diffTime / (1000 * 60 * 60 * 24); // Convertir de milisegundos a días
    };
  
    const nights = calculateNights(startDate, endDate);
  

  const subtotal = cart.reduce((acc, item) => acc + item.precio * nights, 0);
  const iva = subtotal * 0.19;
  const total = subtotal + iva;

  const handleContinue = () => {
    navigate('/Reserva/FormularioPago');
  };

  const handleClearCart = () => {
    clearCart();
  };

  return (
    <div className="contenedor grid grid-row-2 bg-gray-100 shadow-lg font-montserrat gap-2 relative p-4">
      <div className="titulo font-bold text-xl pb-4">
        <p>DETALLES DE RESERVA</p>
        <p><FontAwesomeIcon icon={faCalendar} /> {formattedStartDate} al {formattedEndDate}</p>
      </div>
      <div className="calculadora flex flex-col gap-y-4">
        {cart.map((item, index) => (
          <div key={index} className="cabaña border-b-2 flex justify-between items-center">
            <div>
              <p className="text-left font-bold">{item.tipo}</p>
              <p>Capacidad <span>{item.capacidad}</span> personas</p>
            </div>
            <div className='flex justify-end'>
              <p>${item.precio}</p>
              <button 
                onClick={() => removeItemFromCart(item.id)} 
                className="ml-4 text-red-500 hover:text-red-700"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
        <div className="subtotal flex justify-between ">
          <div>
          <p className="font-bold">SUBTOTAL</p>
          <p className='text-left'><span>{nights}</span> Noches</p>
          </div>
          <p>${subtotal.toFixed(0)}</p>
        </div>
        <div className="iva flex justify-between border-b-2 ">
          <p className="font-bold">IVA</p>
          <p>+ ${iva.toFixed(0)}</p>
        </div>
        <div className="total flex justify-between font-bold text-xl">
          <p className="">TOTAL</p>
          <p>${total.toFixed(0)}</p>
        </div>
      </div>
      <button 
        onClick={handleContinue} 
        className={`mt-6 py-2 h-auto border-none cursor-pointer font-bold ${
          cart.length === 0 ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-elqui text-white'
        }`}
        disabled={cart.length === 0}
      >
        CONTINUAR
      </button>
      <button onClick={handleClearCart} className="mt-2 text-elqui h-auto border-none cursor-pointer text-sm">
        Limpiar carrito
      </button>
    </div>
  );
};

export default DetallesReserva;