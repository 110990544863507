import React, { useState, useEffect } from 'react';
import './css/TiposCabañas.css';
import CabañaFamiliar from '../../images/Cabañas/CabañaFamiliar.png';
import CabañaDoble from '../../images/Cabañas/CabañaDoble.png';
import CabañaDobleDeluxe from '../../images/Cabañas/CabañaDobleDeluxe.png';
import interiorCabañaFamiliar2 from '../../images/CabanaInterior/InteriorCabanaPreliminar5.png';
import interiorCabañaFamiliar3 from '../../images/CabanaInterior/InteriorCabanaPreliminar6.png';
import interiorCabañaFamiliar1 from '../../images/CabanaInterior/InteriorCabanaFamiliar.png';
import interiorCabañaDoble2 from '../../images/CabanaInterior/InteriorCabanaPreliminar5.png';
import interiorCabañaDoble3 from '../../images/CabanaInterior/InteriorCabanaPreliminar6.png';
import interiorCabañaDoble1 from '../../images/CabanaInterior/InteriorCabanaDoble.png';
import interiorCabañaDobleDeluxe2 from '../../images/CabanaInterior/InteriorCabanaPreliminar5.png';
import interiorCabañaDobleDeluxe3 from '../../images/CabanaInterior/InteriorCabanaPreliminar6.png';
import interiorCabañaDobleDeluxe1 from '../../images/CabanaInterior/InteriorCabanaDobleDeluxe.png';


const TiposCabañas = () => {
    const imagesCabañaFamiliar = [interiorCabañaFamiliar1,CabañaFamiliar, interiorCabañaFamiliar2, interiorCabañaFamiliar3];
    const [imageIndexFamiliar, setImageIndexFamiliar] = useState(0);
    const [imageSrcFamiliar, setImageSrcFamiliar] = useState(imagesCabañaFamiliar[imageIndexFamiliar]);
    const [isHoveringFamiliar, setIsHoveringFamiliar] = useState(false);

    const imagesCabañaDoble = [interiorCabañaDoble1,CabañaDoble, interiorCabañaDoble2, interiorCabañaDoble3];
    const [imageIndexDoble, setImageIndexDoble] = useState(0);
    const [imageSrcDoble, setImageSrcDoble] = useState(imagesCabañaDoble[imageIndexDoble]);
    const [isHoveringDoble, setIsHoveringDoble] = useState(false);

    const imagesCabañaDobleDeluxe = [interiorCabañaDobleDeluxe1,CabañaDobleDeluxe, interiorCabañaDobleDeluxe2, interiorCabañaDobleDeluxe3];
    const [imageIndexDobleDeluxe, setImageIndexDobleDeluxe] = useState(0);
    const [imageSrcDobleDeluxe, setImageSrcDobleDeluxe] = useState(imagesCabañaDobleDeluxe[imageIndexDobleDeluxe]);
    const [isHoveringDobleDeluxe, setIsHoveringDobleDeluxe] = useState(false);

    useEffect(() => {
        let timer;
        if (isHoveringFamiliar) {
            timer = setInterval(() => {
                if (isHoveringFamiliar) {
                    setImageIndexFamiliar((prevIndex) => {
                        const nextIndex = (prevIndex + 1) % imagesCabañaFamiliar.length;
                        return nextIndex === 0 ? 1 : nextIndex;
                    });
                }
            }, 1000);
        } else {
            setImageIndexFamiliar(0);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isHoveringFamiliar]);

    useEffect(() => {
        setImageSrcFamiliar(imagesCabañaFamiliar[imageIndexFamiliar]);
    }, [imageIndexFamiliar, imagesCabañaFamiliar]);

    useEffect(() => {
        let timer;
        if (isHoveringDoble) {
            timer = setInterval(() => {
                if (isHoveringDoble) {
                    setImageIndexDoble((prevIndex) => {
                        const nextIndex = (prevIndex + 1) % imagesCabañaDoble.length;
                        return nextIndex === 0 ? 1 : nextIndex;
                    });
                }
            }, 1000);
        } else {
            setImageIndexDoble(0);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isHoveringDoble]);

    useEffect(() => {
        setImageSrcDoble(imagesCabañaDoble[imageIndexDoble]);
    }, [imageIndexDoble, imagesCabañaDoble]);

    useEffect(() => {
        let timer;
        if (isHoveringDobleDeluxe) {
            timer = setInterval(() => {
                if (isHoveringDobleDeluxe) {
                    setImageIndexDobleDeluxe((prevIndex) => {
                        const nextIndex = (prevIndex + 1) % imagesCabañaDobleDeluxe.length;
                        return nextIndex === 0 ? 1 : nextIndex;
                    });
                }
            }, 1000);
        } else {
            setImageIndexDobleDeluxe(0);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isHoveringDobleDeluxe]);

    useEffect(() => {
        setImageSrcDobleDeluxe(imagesCabañaDobleDeluxe[imageIndexDobleDeluxe]);
    }, [imageIndexDobleDeluxe, imagesCabañaDobleDeluxe]);

    return (
        <div className="containerTiposCabañas">
            <div className="FilasCabañas">
                <a href="Cabañas/CabañaFamiliar"
                    onMouseEnter={() => setIsHoveringFamiliar(true)}
                    onMouseLeave={() => setIsHoveringFamiliar(false)}
                >
                    <div className='descriptionTiposCabañas'> 
                        <div className='imageTipoContainer'>
                            <img src={imageSrcFamiliar} alt="" />
                            <p className='Tipo'>CABAÑA FAMILIAR</p>
                            <p className='Personas'>Máximo 4 huéspedes</p>
                        </div>
                    </div>
                    
                </a>
            </div>
            <div className="FilasCabañas">
                <a href="Cabañas/CabañaDoble"
                    onMouseEnter={() => setIsHoveringDoble(true)}
                    onMouseLeave={() => setIsHoveringDoble(false)}
                >
                    <div className='descriptionTiposCabañas'> 
                        <div className='imageTipoContainer'>
                            <img src={imageSrcDoble} alt="" />
                            <p className='Tipo'>CABAÑA MATRIMONIAL</p>
                            <p className='Personas'>Máximo 2 huéspedes</p>
                        </div>
                       
                    </div>
                    
                </a>
            </div>
            <div className="FilasCabañas">
                <a href="Cabañas/CabañaDobleDeluxe"
                    onMouseEnter={() => setIsHoveringDobleDeluxe(true)}
                    onMouseLeave={() => setIsHoveringDobleDeluxe(false)}
                >
                    <div className='descriptionTiposCabañas'> 
                        <div className='imageTipoContainer'>
                            <img src={imageSrcDobleDeluxe} alt="" />
                            <p className='Tipo'>CABAÑA MATRIMONIAL SUPERIOR</p>
                            <p className='Personas'>Máximo 2 huéspedes</p>
                        </div>
                    </div>
                    
                </a>
            </div>
        </div>
    );
};

export default TiposCabañas;