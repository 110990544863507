import React from 'react';
import Servicio1 from "../../images/Servicios/Servicio1.png"
import Servicio2 from "../../images/Servicios/Servicio2.png"
import Servicio3 from "../../images/Servicios/Servicio3.png"
import Servicio5 from "../../images/Servicios/Servicio5.png"
import Servicio6 from "../../images/Servicios/Servicio6.png"
import './css/VerServicios.css';

function VerServicios() {
    const servicios = [
        { img: Servicio1, titulo: "TINAJAS" },
        { img: Servicio2, titulo: "QUINCHO" },
        { img: Servicio3, titulo: "PISCINA" },
        { img: Servicio5, titulo: "WIFI" },
        { img: Servicio6, titulo: "MASAJES" },
    ];

    return (
        <div className="containerVerServicios">
            <p className='descripcion2'>NUESTROS SERVICIOS</p>
            <p className='descripcion1'>Todo lo que necesitas para una estancia inolvidable</p>
            <div className='Servicios-Container'>
                {servicios.map((servicio, index) => (
                    <a href="/Servicios" key={index} className="Servicio-Wrapper">
                        <div className='contenedor-servicio'>
                            <img className="Imagen-Servicio" src={servicio.img} alt={servicio.titulo} /> 
                            <div className="Titulo-Servicio"> 
                                <p>{servicio.titulo}</p> 
                            </div>  

                        </div>
                    </a>

                ))}
            </div>
        </div>
    );
}

export default VerServicios;