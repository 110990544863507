import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Inicio from './pages/inicio';
import Cabañas from './pages/Cabañas';
import DescripcionCabañas from './pages/DescripcionCabañas';
import Servicios from './pages/Servicios';
import Experiencias from './pages/Experiencias';
import Reserva from './pages/Reserva'; 
import FormularioPago from './pages/FormularioPago';
import { DateProvider } from './components/Context/DateContext';
import { ShoppingCartProvider } from './components/Context/ShoppingCartContext';
import './App.css';
import ResetURL from './components/Common/ResetUrl';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useLocation } from 'react-router-dom';
import PagoExitoso from './pages/PagoExitoso';
import PagoPendiente from './pages/PagoPendiente';
import PagoFallido from './pages/PagoFallido';
import Anfitrion from './pages/Anfitrion';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
      duration: 1000,
    }); 
  }, []);

  return (
    <Router> {/* Router envuelve todo */}
      <ScrollToTop />
      <DateProvider> 
        <ShoppingCartProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path="/Cabañas" element={<Cabañas />} />
              <Route path="/Cabañas/:tipo" element={<DescripcionCabañas />} />
              <Route path="/Servicios" element={<Servicios />} />
              <Route path="/Experiencias/:tipo" element={<Experiencias />} />
              <Route path="/Reserva" element={<Reserva />} />
              <Route path="/Reserva/FormularioPago" element={<FormularioPago />} />
              <Route path="/PagoExitoso" element={<PagoExitoso />} />
              <Route path="/PagoPendiente" element={<PagoPendiente />} />
              <Route path="/PagoFallido" element={<PagoFallido />} />
              <Route path="/Anfitrion" element={<Anfitrion />} />


              <Route path="*" element={<ResetURL />} />
            </Routes>
          </div>
        </ShoppingCartProvider>
      </DateProvider>
    </Router>
  );
}

export default App;