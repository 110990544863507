import React, { useEffect, useState } from 'react';
import './css/Experiencias.css';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import Slide from '../components/Card/ImageSlider';
import { useParams, useNavigate } from 'react-router-dom';
import { useWindowScroll } from '@uidotdev/usehooks';
import imagenObservatorio from '../images/Experiencia/ImagenObservatorioResponse.png';
import imagenTours from '../images/Experiencia/ImagenToursResponse.png';
import imagenObservatorio2 from '../images/Experiencia/ImagenObservatorio2.jpg';
import imagenObservatorio2Response from '../images/Experiencia/ImagenObservatorio2.jpg';
import Cabalgatas from '../images/Experiencia/Cabalgatas.png';
import Paseo from '../images/Experiencia/Paseo.png';
import Pisqueras from '../images/Experiencia/Pisqueras.png';
import Gastronomia from '../images/Experiencia/Gastronomia.png';
const Experiencias = () => {
    const [{ y }] = useWindowScroll();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [anchoVentana, setAnchoVentana] = useState(window.innerWidth);
    const { tipo } = useParams();
    const navigate = useNavigate();
    let textoBienvenida;
    let imagen1;
    let tituloBienvenida;
    let textoBienvenidaMarcado;
    let imagen2;
    let imagen3;
    let imagen4;
    let imagen5;
    let classNameImagen;
    const [imagenActualObservatorio, setImagenActual] = useState(imagen2);
    switch (tipo) {
        case 'Observatorio':
            textoBienvenida = 'Adéntrate en el universo desde nuestro exclusivo observatorio, donde las estrellas del Valle del Elqui se convierten en tu espectáculo nocturno personal. Situado en un entorno privilegiado y lejos de la contaminación lumínica, nuestro observatorio ofrece una experiencia única de contemplación celestial. Con la guía de astrónomos expertos, descubre los misterios del cosmos mientras te sumerges en la fascinante belleza del firmamento. Prepárate para una noche inolvidable bajo el cielo estrellado del Valle del Elqui.';
            imagen1 = imagenObservatorio;
            tituloBienvenida = 'TOUR ASTRONÓMICO DE LUJO EN'
            textoBienvenidaMarcado = 'OBSERVATORIO GALILEO'
            imagen2 = imagenObservatorio2;
            classNameImagen = 'ImagenPrincipalExperienciaObservatorio';
            break;
        case 'Tours':
            textoBienvenida = 'Pisco Elqui, un encantador pueblo enclavado en el corazón del Valle del Elqui, es un destino turístico imperdible que combina belleza natural, historia y cultura. Famoso por sus cielos despejados y su entorno sereno, Pisco Elqui ofrece una variedad de actividades para todos los gustos.';
            imagen1 = imagenTours;
            tituloBienvenida = 'SORPRÉNDETE EN'
            textoBienvenidaMarcado = 'PISCO ELQUI'
            classNameImagen = 'ImagenPrincipalExperienciaTours';
            imagen2 = Cabalgatas;
            imagen3 = Paseo;
            imagen4 = Pisqueras;
            imagen5 = Gastronomia;
            break;

    }
    useEffect(() => {
        if (tipo !== 'Observatorio' && tipo !== 'Tours') {
            navigate('/');
        }
    }, [tipo, navigate]);

    useEffect(() => {
        const handleResize = () => {
            setAnchoVentana(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 1135) {
            setScrollPosition(1);
            setImagenActual(imagenObservatorio2Response)
        }
        else if (y > 700) {
            setScrollPosition(1);
            setImagenActual(imagen2)
        } else {
            setScrollPosition(0);
            setImagenActual(imagen2)
        }
    }, [y, anchoVentana]);


    return (
        <div className="contenedorExperiencias" >
            <Header scrollPosition={scrollPosition} className="fixed-header-Experiencias" />
            <div className='cuerpo-Experiencias'>
                <div className='SlideExperiencia'>
                    <Slide tipo={tipo} />
                </div>
    
                <div className='DescripcionExperiencia' data-aos="fade-up">
                    <p>{textoBienvenida}</p>
                </div>
                {tipo === 'Observatorio' && (
                    <div className='PreciosObservatorio' data-aos="fade-up">
                        <div className='contenedorImagen'>
                            <img src={imagenActualObservatorio} alt="Observatorio" />
                        </div>
                        <div className='Descripcion'>
                            <div className='Des1'>
                                <div className='Titulo'><strong>VALORES</strong></div>

                                <p><strong>Adultos:</strong></p>
                                <p>$20.000 CLP / US$35</p>
                                <p><strong>Niños menores de 12 años:</strong></p>
                                <p>$15.000 CLP / US$30</p>
                                <p><strong>Duración del tour:</strong></p>
                                <p>2 horas aproximadamente</p>
                            </div>
                            <div className='Des2'>
                                <div className='Titulo'><strong>RESERVA</strong></div>
                                <p>Puedes reservas escribiéndonos </p>
                                <p>a reservas@misteriosdelelqui.cl</p>
                                <br />
                                <p>También puedes contactarnos directamente al</p>
                                <p>+56 9 44 004 032</p>
                                <br />
                                <p>PARA MÁS INFORMACIÓN INGRESE A:</p>
                                <p><strong>https://www.observatoriogalileo.cl/</strong></p>
                            </div>


                        </div>
                    </div>
                )}
                {tipo === 'Tours' && (
                    <div className='Tours' data-aos="fade-up">
                        <div className='contenedorImagenes1'>
                            <div className='Imagen1'> 
                                <p><strong>CABALGATAS</strong></p>
                                <img src={imagen2} alt="Cabalgatas" />
                            </div>
                            <div className='Imagen2'>
                                <p><strong>PASEOS EN BICICLETA</strong></p>
                                <img src={imagen3} alt="Cabalgatas" />
                            </div>

                        </div>
                        <div className='contenedorImagenes2'>
                            <div className='Imagen1'>
                                <p><strong>CONOCE LAS VIÑAS Y PISQUERAS</strong></p>
                                <img src={imagen4} alt="Cabalgatas" />
                            </div>
                            <div className='Imagen2'>
                                <p><strong>SORPRÉNDETE CON LA GASTRONOMÍA</strong></p>
                                <img src={imagen5} alt="Cabalgatas" />
                            </div>
                        </div>
                    </div>
                )}
                <div className='outer-div-Footer-Experiencias'>
                    <Footer />
                </div>
            </div>
        </div>

    )
}
export default Experiencias;