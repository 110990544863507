import React, { useEffect, useState } from 'react';
import './css/Reserva.css';
import Header from '../components/Header/header';
import Slide from '../components/Card/ImageSlider';
import { useWindowScroll } from '@uidotdev/usehooks';
import { useDate } from '../components/Context/DateContext'; 
import ListaCabañasDisponibles from '../components/Card/ListaCabañasDisponibles.jsx'; // Asegúrate de importar el componente correcto
import Cart from '../components/Card/Cart';
import Footer from '../components/Footer/Footer'; 

const Reserva = () => {
  const [{ y }] = useWindowScroll();
  const [scrollPosition, setScrollPosition] = useState(0);
  const { startDate, endDate } = useDate();
  
  useEffect(() => {
    if (y > 700) {
      setScrollPosition(1);
    } else {
      setScrollPosition(0);
    }
  }, [y]);

  return (
    <div className="contenedorReserva">
      <Header scrollPosition={scrollPosition} className="fixed-header" />
      <div className="cuerpoReserva">
        <Slide tipo="Reserva" /> 
        <div className='my-20 flex flex-col w-full items-center'>
          <ListaCabañasDisponibles /> {/* Aquí se mostrarán las cabañas disponibles */}
          <div className='my-20 w-full'>
            <Cart />
          </div>
        </div>
      </div>
      <div className='outer-div-Footer'>
        <Footer />
      </div>
    </div>
  );
};

export default Reserva;