import ButtonBuscar from '../Button/ButtonBuscar';
import './css/Calendar.css'

const Calendar = () => {
  return (
    <div className="white-box flex flex-col">
      <div className='font-montserrat font-semibold pr-2 text-elqui'>
        ¡BIENVENIDOS! 
      </div>
      <div className='divBotonBuscar'>
        <ButtonBuscar />
      </div>
    </div>
  );
};

export default Calendar;