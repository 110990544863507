import React from 'react';
import './css/ButtonHablemos.css'; // Asegúrate de tener este archivo CSS en la misma carpeta
const ButtonHablemos = () => {
  const handleClick = () => {
    window.open('https://wa.me/56995109187', '_blank');
  };

  return (
    <button className="ButtonHablemos gap-x-1" onClick={handleClick}> 
     HABLEMOS
    </button>
  );
};

export default ButtonHablemos;