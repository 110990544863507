import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDate } from './DateContext';

// Crear el contexto
const ShoppingCartContext = createContext();

// Proveedor del contexto
export const ShoppingCartProvider = ({ children }) => {
    const { startDate, endDate } = useDate();
    const [cart, setCart] = useState([]);

    // Función para agregar o actualizar un ítem en el carrito
    const addItemToCart = (item) => {   
        setCart((prevCart) => {
            const existingItemIndex = prevCart.findIndex(cartItem => cartItem.id === item.id);
            if (existingItemIndex !== -1) {
                // Si el ítem ya existe, actualiza la cantidad o el número de personas
                const updatedCart = [...prevCart];
                updatedCart[existingItemIndex] = {
                    ...updatedCart[existingItemIndex],
                    personas: item.personas,
                    // Otras propiedades que se puedan actualizar...
                };
                return updatedCart;
            } else {
                // Si el ítem no existe, lo agrega al carrito
                return [...prevCart, item];
            }
        });    
    };

    // Función para eliminar un ítem del carrito
    const removeItemFromCart = (id) => {
        setCart((prevCart) => prevCart.filter(cartItem => cartItem.id !== id));
    };

    // Función para limpiar el carrito
    const clearCart = () => {
        setCart([]);
    };

    // Reiniciar el carrito cuando cambien las fechas
    useEffect(() => {
        setCart([]);
    }, [startDate, endDate]);

    return (
        <ShoppingCartContext.Provider value={{ cart, addItemToCart, removeItemFromCart, clearCart }}>
            {children}
        </ShoppingCartContext.Provider>
    );
};

// Hook personalizado para usar el contexto
export const useShoppingCart = () => useContext(ShoppingCartContext);