import React from 'react';
import './css/VerExperiencias.css';
import Experiencia1 from '../../images/Experiencia/Experiencia1.png';
import Experiencia3 from '../../images/Experiencia/Experiencia3.png';
import './css/VerServicios.css';

function VerServicios() {
    return (
        <div className="card-experiencias pt-16">
            <div className="containerVerServicios">
                <p className='descripcion2'>EXPERIENCIAS ÚNICAS</p>
            </div>
            <div className="filaExperienciaTipos">
                <a href="/Experiencias/Observatorio"> 
                    <div className="columna-tipo-experiencias"> 
                        <div className='ContenedorImagen'> 
                            <p className='NombreExperiencia'><strong>OBSERVATORIO</strong></p>
                            <img src={Experiencia1} alt="Experiencia 1" />
                        </div> 
                    </div>
                </a>
                <a href="/Experiencias/Tours">
                    <div className="columna-tipo-experiencias">
                        <div className='ContenedorImagen'>
                            <p className='NombreExperiencia'><strong>TOURS EXTERNOS</strong></p>
                            <img src={Experiencia3} alt="Experiencia 1" />
                        </div> 
                    </div>
                </a>
            </div>
        </div>
    );
}

export default VerServicios